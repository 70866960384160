/* src/components/Story.css */
.section.story {
    display: flex;
    flex-direction: column; /* Stack title and content vertically */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    min-height: 100vh; /* Make sure the section takes up the full viewport height */
    padding: 20px;
    padding-top: 0px;
    text-align: center;
  }
  
  
  .story-content {
    max-width: 800px; /* Optional: limit the width of the content */
    width: 100%;
  }
  
  /* Loading spinner styles */
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

.story-title {
    font-size: 1.6rem; /* Font size for the title */
    font-weight: bold;
    padding: 0;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
}