/* src/components/Home.css */

/* Home container */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  text-align: center;
}

@supports (height: 100dvh) {
  .home-container {
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100dvh;
  }
}

.home-title {
  font-size: 2.6rem; /* Adjust size as needed */
  font-weight: bold;
  margin-top: 0; /* Remove any top margin */
  font-family: Arial, Helvetica, sans-serif;
}

.image-container {
  margin: 20px;
}

.homepage-image {
  max-height: 500px;
  height: auto;
  width: auto;

}

/* Navbar styling */
.home-navbar {
  display: flex;
  justify-content: center;
  vertical-align: bottom;
}

.homenavbar-links {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  flex-direction: row;
  margin-bottom: 10px;
}

.homenavbar-links li {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0 30px;
  position: relative;
}

.homenavbar-links li a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.homenavbar-links li::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(255, 99, 71, 0.3);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease-in-out;
  z-index: 0;
}

.homenavbar-links li:hover::before {
  transform: scaleX(1);
}

/* Responsive styling for small screens */
@media (max-width: 640px) {
  .homenavbar-links {
    flex-direction: column;
  }

  .homenavbar-links li {
    margin: 2px 0;
    font-size: 1.7rem;
  }

  .home-navbar {
    margin-top: 0;
  }

  .image-container {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-items: center;
    max-height: 450px;
  }

  .image-container .homepage-image {
    max-height: 300px;
  }

  .home-container {
    height: auto;
    overflow-y: scroll;
    align-items: center;
    justify-content: center;
  }

  .home-title {
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 2.3rem;
    font-family: Arial, Helvetica, sans-serif;
  }
}
