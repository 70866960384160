.section.art-gallery {
    text-align: center; /* Centers the text */
    width: 100%;
  }

.art-title {
    font-size: 1.6rem; /* Font size for the title */
    font-weight: bold;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
 }