@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.section.me {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 80vh;
    padding: 10px; /* Padding for the section */
    text-align: center;
  }
  
  .me-title {
    font-size: 1.6rem; /* Font size for the title */
    font-weight: bold;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .me-content {
    display: flex;
    flex-direction: column;
    max-width: 600px; /* Max width for the content */
    width: 100%;
  }
  
  .me-image {
    max-width: auto;
    height: auto;
    border-radius: 8px;
    margin-bottom: 5px;
    padding-top: 30px; /* Add top padding */
    padding-bottom: 30px; /* Add bottom padding */
  }
  
  .me-description {
    font-size: 1.3rem;
    line-height: 1.2;
    margin-bottom: 5px;
    font-weight: 100;
  }
  
  .me-link {
    font-size: 1.3rem;
    color: #000;
    text-decoration: none;
  }
  
  .me-link:hover {
    text-decoration: underline;
  }
  